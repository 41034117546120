import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { ReactComponent as Thumb } from './assets/thumb.svg'
import { ReactComponent as People } from './assets/people.svg'
import { ReactComponent as Yoga } from './assets/yoga.svg'

import * as styles from './Header.module.css';

type Props = {
  className?: string;
};

const Statistic = ({ statistic, label }: { statistic: React.ReactNode; label: React.ReactNode }): JSX.Element => {
  return (
    <div className={styles.statistic}>
      <h2>{statistic}</h2>
      <p className={styles.label}>{label}</p>
    </div>
  );
};

export const Header = (_: Props): JSX.Element => {
  return (
    <header className={styles.wrapper}>
      <div className={styles.content}>
        <p className={styles.mission}>Our mission</p>
        <h1 className={styles.title}>Making business insurance simple, fast and fair</h1>
        <div className={styles.usps}>
          <p className={styles.usp}>
            <Yoga /><br />
            <strong>Simple questions.</strong><br /> Crystal clear insurance.
          </p>
          <p className={styles.usp}>
            <Thumb /><br />
            <strong>Fast. </strong><br />Covered in 5 minutes.
          </p>
          <p className={styles.usp}>
            <People /><br />
            <strong>Fair. </strong><br />Razor sharp prices, no hidden costs.
          </p>
        </div>
      </div>

      <div className={styles.peopleWrapper}>
        <StaticImage
          placeholder='tracedSVG'
          className={styles.person}
          objectFit='contain'
          breakpoints={[1440]}
          src='./assets/images/people/1.jpg'
          alt='header-image'
        />
        <StaticImage
          placeholder='tracedSVG'
          className={styles.person}
          objectFit='contain'
          breakpoints={[1440]}
          src='./assets/images/people/2.jpg'
          alt='header-image'
        />
        <StaticImage
          placeholder='tracedSVG'
          className={styles.person}
          objectFit='contain'
          breakpoints={[1440]}
          src='./assets/images/people/3.jpg'
          alt='header-image'
        />
        <StaticImage
          placeholder='tracedSVG'
          className={styles.person}
          objectFit='contain'
          breakpoints={[1440]}
          src='./assets/images/people/4.jpg'
          alt='header-image'
        />
        <StaticImage
          placeholder='tracedSVG'
          className={styles.person}
          objectFit='contain'
          breakpoints={[1440]}
          src='./assets/images/people/5.jpg'
          alt='header-image'
        />
      </div>
      <div className={[styles.content, styles.statsWrapper].join(' ')}>
        <h2 className={styles.statsIntro}>The easiest way to insure your business</h2>
        <div className={styles.stats}>
          <Statistic statistic={<strong>1,500+</strong>} label={'Professions covered'} />
          <Statistic statistic={<strong>2 min</strong>} label={'To get a quote'} />
          <Statistic
            statistic={
              <>
                <strong>4.7</strong>/5
              </>
            }
            label={'Average review rating'}
          />
        </div>
      </div>
    </header>
  );
};
