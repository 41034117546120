// extracted by mini-css-extract-plugin
export var content = "Header-module--content--ItWRK";
export var label = "Header-module--label--AjNDX";
export var mission = "Header-module--mission--gWQNw";
export var peopleWrapper = "Header-module--peopleWrapper--3FUkS";
export var statistic = "Header-module--statistic--uOiOM";
export var stats = "Header-module--stats--YWhnG";
export var statsIntro = "Header-module--statsIntro--6ejJ3";
export var statsWrapper = "Header-module--statsWrapper--dgL1V";
export var title = "Header-module--title--bWXxd";
export var usp = "Header-module--usp--y+N8h";
export var usps = "Header-module--usps--VwQkk";
export var wrapper = "Header-module--wrapper--NtSYw";