import React from 'react';

import * as styles from './Navigation.module.css';
import { ReactComponent as DutchFlag } from './images/dutch-flag.svg';
import { ReactComponent as LogoWithText } from './images/logo-with-text.svg';

type Props = {
  className?: string;
};

export const Navigation = (_: Props): JSX.Element => {
  return (
    <nav className={styles.wrapper}>
      <LogoWithText className={styles.logo} />
      <a className={[styles.link, styles.embedded].join(' ')} href='https://insify.nl/embedded'>
        Embedded insurance
      </a>
      <a className={styles.link} href='https://careers.insify.nl/' target={'_blank'} rel='noreferrer'>
        We’re hiring!
      </a>
      <a href='https://insify.nl' className={styles.homeLink}>
        <DutchFlag />
        <div className={styles.url}>Insify.nl</div>
      </a>
    </nav>
  );
};
