import React from 'react';

import * as styles from './Press.module.css';
import { ReactComponent as Download } from './assets/download.svg';

type Props = {
  className?: string;
};

type Article = {
  title: string;
  date: string;
  link: string;
};

export const Press = (_: Props): JSX.Element => {
  const articles: Article[] = [
    {
      title: `Insify raises €15 million Series A round led by Accel to bring a new generation of business insurance to Europe's SMEs`,
      date: 'Feb 10, 2022.',
      link: 'https://insify.nl/in-de-pers/series-a-en',
    },
    {
      title: 'Insify launches do-it-yourself insurance for entrepreneurs in construction. ',
      date: 'Dec 8, 2021.',
      link: 'https://insify.nl/in-de-pers/release-bouw',
    },
    {
      title: 'Insurtech Insify insures bol.com sales partners.',
      date: 'Aug 25, 2021.',
      link: 'https://insify.nl/in-de-pers/samenwerking-bol-com',
    },
    {
      title: 'New Dutch start-up Insify focuses on small business insurance.',
      date: 'Dec 09, 2020.',
      link: 'https://insify.nl/in-de-pers/release',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Press release</h2>
      {articles.map((article, index) => (
        <div className={styles.article} key={article.title}>
          <h4 className={styles.articleTitle}>{article.title}</h4>
          <p>
            <span className={styles.articleDate}>{article.date}</span>
            <a href={article.link} target={'_blank'} rel='noreferrer'>
              Read now {index !== 0 ? ' (dutch)' : null}
            </a>
          </p>
        </div>
      ))}
      <div className={styles.pressKit}>
        <h2>Downloads</h2>
        <a href='https://downloads.ctfassets.net/02n7gb7dxyys/2eA5s9Ju1DqeHtGqATP2N5/ae1bd72942b55d639e3c78771afb3dc7/insify-press-kit.zip' className={styles.pressKitDownload}>
          <Download />
          Presskit 2022
        </a>
      </div>
    </div>
  );
};
