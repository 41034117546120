import React from 'react';

import * as styles from './Values.module.css';

type Props = {
  className?: string;
  image: JSX.Element;
  label: string;
  title: string;
  description: string;
  values: Value[];
};

type Value = {
  icon: React.ReactNode;
  title: string;
  description: string;
};

export const Values = ({ image, label, title, description, values, className = '' }: Props): JSX.Element => {
  return (
    <div className={`${styles.backdrop} ${className}`}>
      <div className={styles.wrapper}>
        <div className={styles.image}>{image}</div>
        <div className={styles.label}>{label}</div>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
        {values.map((value, index) => (
          <div key={value.title} className={[styles.value, index === 0 ? styles.first : ''].join(' ')}>
            <h3>
              {value.icon} {value.title}
            </h3>
            <p>{value.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
