import React from 'react';

import * as styles from './Partners.module.css';
import { ReactComponent as LogoMre } from './assets/logo-mre.svg';
import { ReactComponent as LogoWakam } from './assets/logo-wakam.svg';
import { ReactComponent as LogoIptiq } from './assets/logo-iptiq.svg';

type Props = {
  className?: string;
};

export const Partners = (_: Props): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.label}>Our partners</h2>
      <div className={styles.partners}>
        <div className={styles.logo}>
          <LogoMre />
        </div>
        <div className={styles.logo}>
          <LogoWakam />
        </div>
        <div className={styles.logo}>
          <LogoIptiq />
        </div>
      </div>
    </div>
  );
};
