import * as React from 'react';

import '../assets/global.css';
import '../assets/variables.css';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Investors } from '../components/Investors';
import { Layout } from '../components/Layout';
import { Navigation } from '../components/Navigation';
import { Partners } from '../components/Partners';
import { Press } from '../components/Press';
import { Seo } from '../components/Seo';
import { Team } from '../components/Team';
import { ValuesEntrepeneurs } from '../components/ValuesEntrepeneurs';
import { ValuesScale } from '../components/ValuesScale';

import '@insify/css/app.global.css';

const IndexPage = (): JSX.Element => {
  return (
    <Layout>
      <Seo />
      <Navigation />
      <Header />
      <ValuesEntrepeneurs />
      <Team />
      <ValuesScale />
      <Partners />
      <Investors />
      <Press />
      <Footer />
    </Layout>
  );
};

// eslint-disable-next-line import/no-default-export
export default IndexPage;
