import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

type Props = { description?: string; lang?: string; title?: string };
type Site = {
  site: {
    siteMetadata: {
      name: string;
      title: string;
      description: string;
      socialImage: string;
    };
  };
};

export const Seo = ({ lang }: Props): JSX.Element => {
  const { site } = useStaticQuery<Site>(
    graphql`
      query {
        site {
          siteMetadata {
            name
            title
            description
            socialImage
          }
        }
      }
    `,
  );

  const offer = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: site.siteMetadata.title,
    description: site.siteMetadata.description,
    image: site.siteMetadata.socialImage,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={site.siteMetadata.name}
      meta={[
        { name: 'robots', content: 'noindex, follow'},
        {
          name: `description`,
          content: site.siteMetadata.description,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: site.siteMetadata.description,
        },
        {
          property: `og:image`,
          content: site.siteMetadata.socialImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: site.siteMetadata.description,
        },
      ]}>
      <script type='application/ld+json'>{JSON.stringify([offer])}</script>
    </Helmet>
  );
};
