import React from 'react';

import * as styles from './Investors.module.css';
import { ReactComponent as LogoAccel } from './assets/logo-accel.svg';
import { ReactComponent as LogoFlyVentures } from './assets/logo-fly-ventures.svg';
import { ReactComponent as LogoFrontline } from './assets/logo-frontline.svg';
import { ReactComponent as LogoVisionaries } from './assets/logo-visionaries.svg';
import { ReactComponent as LogoMre } from './assets/logo-mre.svg';
import { ReactComponent as LogoOpera } from './assets/logo-opera.svg';
import { ReactComponent as LogoRosberg } from './assets/logo-rosberg.svg';

type Props = {
  className?: string;
};

export const Investors = (_: Props): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <h2>Our investors</h2>
      <div className={styles.investors}>
        <div className={styles.logo}>
          <LogoAccel />
        </div>
        <div className={styles.logo}>
          <LogoVisionaries />
        </div>
        <div className={styles.logo}>
          <LogoFlyVentures />
        </div>
        <div className={styles.logo}>
          <LogoFrontline />
        </div>
        <div className={styles.logo}>
          <LogoMre />
        </div>
        <div className={styles.logo}>
          <LogoRosberg />
        </div>
        <div className={styles.logo}>
          <LogoOpera />
        </div>
      </div>
    </div>
  );
};
