import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { Values } from '../Values';
import * as styles from './ValuesScale.module.css';
import { ReactComponent as Person } from './assets/person.svg';
import { ReactComponent as Phone } from './assets/phone.svg';
import { ReactComponent as Stack } from './assets/stack.svg';

type Props = {
  className?: string;
};

export const ValuesScale = (_: Props): JSX.Element => {
  return (
    <Values
      className={styles.values}
      image={
        <StaticImage
          className={styles.image}
          src='./assets/iphone.png'
          alt='web-app'
          objectFit='contain'
          breakpoints={[3000]}
        />
      }
      label='Designed for scale'
      title={'The worlds most scalable business insurance solution'}
      description='We developed insurance products for entrepreneurs and small businesses in the e-commerce, leisure, construction, and IT sectors, among others, backed by Munich Re. Available across several channels.'
      values={[
        {
          icon: <Phone />,
          title: 'Direct',
          description:
            'Get covered in less than 5 minutes. Insurance that lets entrepreneurs focus on growing their business.',
        },
        {
          icon: <Stack />,
          title: 'Embedded',
          description:
            'Expanding value propositions with plug and play business insurance. For marketplaces, (neo)banks, and SAAS platforms.',
        },
        {
          icon: <Person />,
          title: 'Broker',
          description:
            "The solution that saves time for brokers and their customers. Serving SMEs has never been easier with Insify's broker platform.",
        },
      ]}
    />
  );
};
