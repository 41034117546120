import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { Values } from '../Values';
import * as styles from './ValuesEntrepeneurs.module.css';
import { ReactComponent as Phone } from './assets/phone.svg';
import { ReactComponent as Recycle } from './assets/recycle.svg';
import { ReactComponent as FastForward } from './assets/fast-forward.svg';

type Props = {
  className?: string;
};

export const ValuesEntrepeneurs = (_: Props): JSX.Element => {
  return (
    <Values
      className={styles.values}
      image={
        <FastForward className={styles.fastForward} />
      }
      label='Designed for entrepreneurs'
      title={"A new generation of business insurance for Europe's SMEs"}
      description='A technology-first approach to make business insurance simpler, faster and safer.'
      values={[
        {
          icon: <Phone />,
          title: 'End-to-end digital',
          description: '100% automated, fully tailored. Business insurance that scales.',
        },
        {
          icon: <Recycle />,
          title: 'Continuous underwriting',
          description: 'Enhanced data analytics. Insurance that automatically evolves with your business.',
        },
      ]}
    />
  );
};
